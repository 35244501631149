import React from "react"
import styled from "styled-components"

const Indented = styled.div`
  margin-left: 20px;
`

const GuardianshipFAQAccordion = () => {
  return (
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button
              class="btn btn-link"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              什麼是監護人？
            </button>
          </h5>
        </div>

        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div class="card-body">
            監護人是父母指定在英國爲其子女負責的個人或公司。他們代表父母行事，確保孩子在英國學習期間的健康和學業進步。
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              爲什麼我需要指定監護人？
            </button>
          </h5>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordion"
        >
          <ul class="card-body">
            <li>
              英國邊境管理局和英國寄宿學校要求每個 16
              歲以下的學生都有一名居住在英國的教育監護人。
            </li>
            <li>學校通常也要求 16 歲以上的學生有英國監護人。</li>
            <li>
              父母需要有人在以下情況下對他們的孩子負責：
              <Indented>- 在學校：確保他們的學習和學校生活順利進行。</Indented>
              <Indented>
                -
                校外：在旅行期間以及週末和假期期間，確保他們安全、受到照顧並感到舒適。
              </Indented>
            </li>
            <li>
              像 Study Links
              等經驗豐富、關懷備至的監護公司將負責處理實際細節和其他問題，讓家長高枕無憂，學生也可以享受學習。
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              Study Links 能爲我們做什麼？
            </button>
          </h5>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <ul class="card-body">
            <li>
              提供 24/7
              全天候緊急聯繫電話，並在任何困難情況下迅速、專業地爲學生和家長採取行動。
            </li>
            <li>
              對於學生或家長可能存在的任何疑問、擔憂和不確定性，我們可隨時聯繫並提供建議。
            </li>
            <li>
              與學生、他們的父母、他們的代理人和學校保持聯繫，以確保一切順利。
            </li>
            <li>
              爲您孩子的校外生活匹配一個熱情、友好的寄宿家庭，這些寄宿家庭經過仔細培訓和檢查，符合高標準。
            </li>
            <li>安排在英國往返學校、寄宿家庭、機場之間的安全行程。</li>
            <li>安排行動計劃並提供建議，幫助學生學習和制定未來計劃。</li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFour">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Study Links 如何運作？
            </button>
          </h5>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
              Study Links
              是一家由家族經營的公司，所有成員在一個團隊中工作，爲您的家庭提供監護服務。我們的一些團隊成員在辦公室工作，一些成員將親自到學校拜訪學生。
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingFive">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              我們該如何聯繫 Study Links？
            </button>
          </h5>
        </div>
        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#accordion"
        >
          <ul class="card-body">
            <li>
              聯繫我們的最佳方式是通過電子郵件：{" "}
              <a href={"mailto:office@studylinks.co.uk"}>
                office@studylinks.co.uk
              </a>
            </li>
            <li>
              您可以在英國時間週一至週五晚上 9 點至 5 點的辦公時間內致電{" "}
              <a href="tel: +44 0127 4679710">+44 (0)1274 679710</a>
            </li>
            <li>
              我們的緊急電話是{" "}
              <a href="tel: +44 7884 101679">+44 7884 101679</a>
            </li>
            <li>如果辦公室不營業但您立即需要幫助，請撥打緊急電話。</li>
            <li>
              我們可能會通過 Whatsapp
              和微信與您聯繫，但我們的主要溝通渠道是電子郵件。請經常檢查您的電子郵件以獲取重要信息。
            </li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSix">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Study Links 可以在任何學校/大學提供監護服務嗎？
            </button>
          </h5>
        </div>
        <div
          id="collapseSix"
          class="collapse"
          aria-labelledby="headingSix"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
            是的，Study Links 可在整個英國提供監護服務。我們在全英國各地都有員工和寄宿家庭，可在任何緊急情況下爲您提供幫助。



            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingSeven">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              我們應該如何爲孩子在英國學習做準備？

            </button>
          </h5>
        </div>
        <div
          id="collapseSeven"
          class="collapse"
          aria-labelledby="headingSeven"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
            Study Links 爲家長和學生提供有關如何爲英國學習做準備的詳細指南。我們作爲您的監護人在這裏爲您的家庭提供支持。




            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingEight">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              Study Links 可以在假期提供住宿嗎？
            </button>
          </h5>
        </div>
        <div
          id="collapseEight"
          class="collapse"
          aria-labelledby="headingEight"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div>
            可以。Study Links 在學校假期期間爲學生提供家庭寄宿。如果需要，Study Links 也可以安排假期營。



            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingNine">
          <h5 class="mb-0">
            <button
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              Study Links 可以爲監護學生提供哪些額外服務？

            </button>
          </h5>
        </div>
        <div
          id="collapseNine"
          class="collapse"
          aria-labelledby="headingNine"
          data-parent="#accordion"
        >
          <ul class="card-body">
            <li>
            Study Links 爲監護學生及其家庭提供許多額外服務。完整的詳細信息將在註冊時發送。這些額外服務包括：

            </li>
            <li>私人輔導</li>
            <li>
            專業大學申請建議，包括牛津劍橋準備、醫學、法律
            </li>
            <li>爲家庭安排英國旅行</li>
            <li>機場陪同服務</li>
            <li>機場接送</li>
            <li>家庭寄宿</li>
            <li>
            度假營（例如夏令營；復活節營；複習營）
            </li>
            <li>集成研討會</li>
            <li>學習技巧課</li>
            <li>英語會話班</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default GuardianshipFAQAccordion
